.navbar {
  background: #282c34;
  padding: 1em 2em;

  ul {
    max-width: 1024px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0 auto;
  }

  li {
    margin: 0 10px 0 0;

    &.logo {
      margin-right: 20px;

      img {
        max-width: 10em;
        vertical-align: bottom;
      }
    }

    &:last-child {
      justify-content: flex-end;
      margin-left: auto;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

button {
  margin: 0;
  cursor: pointer;
}
