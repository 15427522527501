.create {
  .published-status {
    margin: 1em 0 2em;
  }

  form {
    .label-margin {
      display: block;
      margin-bottom: .3em;
    }

    .center-button {
      margin-top: 15px;
    }
  }
}

.sr-only {
  display: none;
}
