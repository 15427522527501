.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #eee;
}

.content {
  max-width: 1024px;
  width: 100%;
  background: #fff;
  padding: 2em 2em 4em;
  margin: 1em auto 0;
}
