body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.pagination {
  margin: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
}

.pagination_button {
  margin: 1rem 1rem 1rem 1rem;
}
